import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SubscribeForm from "../../components/subscribeForm";
import { Button, HeaderPlaceholder } from "../../styles/ui.styles";

const Racing: React.FC = () => {
  return (
    <>
      <SEO title={"Racing overview"} description={"Racing overview"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>Racing</h1>
          <p>
            Fastrrr offers a compact system for professional sailors and their
            coaches. Through our technology sailors can make data driven
            decisions during racing or training sessions.
          </p>
          <br />
          <p>
            Our racer solution consists of two main components. The first is the
            data collection, which is orchestrated through our hardware
            selection. The second fundamental component is our DataBay online
            software, which allows the analysis of collected data. The software
            assists sailors in many ways, such as providing insights based on
            big data, visualizing real time boat parameters and more. DataBay is
            also compatible with third-party hardware and existing boat
            instruments.
          </p>
          <br />

          <RacingProductsWrapper>
            <RacingProductCard>
              <h4>DinghyEdition</h4>
              <StaticImage
                style={{ width: "100%", height: "200px" }}
                src={"../../images/dinghy_edition.jpg"}
                alt={"DinghyEdition"}
                objectFit={"contain"}
              />
              <Link to={"/products/dinghy-edition"}>
                <Button primary={true}>SEE MORE</Button>
              </Link>
            </RacingProductCard>
            <RacingProductCard>
              <h4>YachtEdition</h4>
              <StaticImage
                style={{ width: "100%", height: "200px" }}
                src={"../../images/yacht_edition.jpg"}
                alt={"YachtEdition"}
                objectFit={"contain"}
              />
              <Link to={"/products/yacht-edition"}>
                <Button primary={true}>SEE MORE</Button>
              </Link>
            </RacingProductCard>
            <RacingProductCard>
              <h4>CoachBoatPro</h4>
              <StaticImage
                style={{ width: "100%", height: "200px" }}
                src={"../../images/coach_boat_pro.jpg"}
                alt={"CoachBoatPro"}
                objectFit={"contain"}
              />
              <Link to={"/products/coach-boat-pro"}>
                <Button primary={true}>SEE MORE</Button>
              </Link>
            </RacingProductCard>
          </RacingProductsWrapper>
        </Wrapper>
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default Racing;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;
  ol ol li {
    list-style-type: lower-latin;
  }
`;

const Graphs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const RacingProductsWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const RacingProductCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 22px;
  box-shadow: var(--shadow);
  a {
    margin: 30px 0;
    text-decoration: none;
  }

  @media (max-width: 480px) {
    a,
    button {
      width: 100%;
    }
  }
`;
